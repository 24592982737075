@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


body,
html {
  background-color: #F3F4F6;
  display: grid;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.025rem;
  font-family: "Inter", sans-serif;
  overflow-x: hidden;
}

[data-error="true"] {
  border: 1px solid red
}

input::placeholder {
  font: 14px Inter;
  line-height: 20px;
  color: #9CA3AF;
}

input:disabled {
  background-color: #F8F9FA;
}

.spin-button-none::-webkit-outer-spin-button,
.spin-button-none::-webkit-inner-spin-button {
  appearance: none;
}